import styled from "@emotion/styled";

export const UserManagementToolContainer = styled.div`
    display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const UserInfoWrapper = styled.div`
    display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const UserNameWrapper = styled.div`
    display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80px;
  
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const AvatarButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const LogoutButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;