import styled from "@emotion/styled";
import {CardContent} from "@mui/joy";

export const CardContainer = styled.li`
    list-style: none;
  cursor: pointer;
  width: 100%;
`;

export const CustomCardContent = styled(CardContent)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 10px 0;
`;

export const TitleWrapper = styled.div`
    display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const LastSeen = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  background: ${({theme}) => theme.vars.palette.customColors.detail3Opacity};
  padding: 4px 6px;
  border-radius: ${({theme}) => theme.vars.radius.sm};
  
  svg {
    fill: ${({theme}) => theme.vars.palette.customColors.detail3}
  }
`;