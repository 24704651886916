import {MyProjectData} from "../pages/dashboard/my-projects/MyProjects";
import {LOCAL_STORAGE_RECENT_KEYS} from "../constants/localStorage.constant";

export type MyRecentsProjectsSeenData = {
    project: MyProjectData;
    lastSeen: Date;
}

export const addProjectToRecent = (projectToAdd :MyProjectData) => {
    const recentSeenProjects = JSON.parse(localStorage.getItem(LOCAL_STORAGE_RECENT_KEYS.RECENT_PROJECTS) || "[]");
    const now = new Date();

    if (!recentSeenProjects) {
        const recentSeenProjectsEmpty :MyRecentsProjectsSeenData[] | [] = [];
        localStorage.setItem(LOCAL_STORAGE_RECENT_KEYS.RECENT_PROJECTS, JSON.stringify(recentSeenProjectsEmpty));
    } else {
        const filteredProjectsToAdd = recentSeenProjects.filter((existingProject :MyRecentsProjectsSeenData) => existingProject?.project?.id !== projectToAdd?.id)
        if (filteredProjectsToAdd.length >= 5) {
            filteredProjectsToAdd.pop();
        }
        filteredProjectsToAdd.unshift({project: projectToAdd, lastSeen: now});
        localStorage.setItem(LOCAL_STORAGE_RECENT_KEYS.RECENT_PROJECTS, JSON.stringify(filteredProjectsToAdd));
    }
}