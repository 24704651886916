export const routes = {
  home: "/",
  dashboard: {
    index: "/dashboard",
    createProject: "/create-project",
    createProjectAbsolute: "/dashboard/create-project",
    myProjects: "/my-projects",
    myProjectsAbsolute: "/dashboard/my-projects",
    projectDetails: "/project/:id",
    projectDetailsAbsolute: "/dashboard/project/:id",
    aiRecommendations: "/project/:id/contract/:contractId/ai-recommendations",
    aiRecommendationsAbsolute:
      "/dashboard/project/:id/contract/:contractId/ai-recommendations",
    contractVisualization: "/project/:projectId/contract-visualization/:contractId",
    contractVisualizationAbsolute: "/dashboard/project/:projectId/contract-visualization/:contractId",
  },
  signIn: "/sign-in",
  signUp: "/sign-up",
  activateAccount: "/activate-account",
  activate: "/activate",
  rememberPassword: "/remember-password",
  resetPassword: "/reset-password",
};
