import { HttpSuccessResponse, UserType } from "types";
import { apiClientWithAuth, apiClient } from "./apiClient.http";

const getLoggedUser = async () => {
  const { data } = await apiClientWithAuth.get<UserType>("/user");

  return data;
};

const confirmAccount = async (token: string) => {
  const { data } = await apiClient.post<HttpSuccessResponse>("/user/confirm", {
    token,
  });

  return data;
};

const resendActivation = async (token: string) => {
  const { data } = await apiClient.post<HttpSuccessResponse>("/user/resend", {
    token,
  });

  return data;
};

type SignInCredentials = {
  email: string;
  password: string;
};

type SignInResponse = {
  accessToken: string;
  refreshToken: string;
};

const signIn = async (credentials: SignInCredentials) => {
  const { data } = await apiClient.post<SignInResponse>(
    "/user/sign-in",
    credentials
  );

  return data;
};

type SignUpRequest = {
  email: string;
  password: string;
};

type SignUpResponse = {
  username: string;
  email: string;
};

const signUp = async (signUpData: SignUpRequest) => {
  const { data } = await apiClient.post<SignUpResponse>("/user", signUpData);

  return data;
};

const sendEmailToResetPassword = async (email: string) => {
  const { data } = await apiClient.post<HttpSuccessResponse>(
    "/user/reset-password",
    { email }
  );

  return data;
};

type ResetPasswordRequest = {
  token: string;
  password: string;
};

const resetPassword = async (resetData: ResetPasswordRequest) => {
  const { data } = await apiClient.post<HttpSuccessResponse>(
    "/user/reset-password",
    resetData
  );

  return data;
};

export const user = {
  getLoggedUser,
  confirmAccount,
  resendActivation,
  signIn,
  signUp,
  sendEmailToResetPassword,
  resetPassword,
};
