import styled from "@emotion/styled";

export const ContractCardContainer = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // max-width: 640px;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 16px;
  border-radius: 10px;
  background: ${({ theme }) => theme.palette.background.body};
`;

export const ButtonAndPragmaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;
