import React from 'react';
import * as S from "./UserManagementTool.styled";
import {Avatar, Typography} from "@mui/joy";
import {useAuth} from "../../../../hooks";
import LogoutIcon from '@mui/icons-material/Logout';
import {theme} from "../../../../styles";

export const UserManagementTool = () => {
    const {user, signOut} = useAuth();

    const initials = user?.username
        .replaceAll(/[^\w\s]/gi, "")
        .substring(0, 2)
        .toUpperCase();

    const handleRedirectToManageAccount = () => {
        //TODO set navigate to manage account page
    };

    const logoutButtonSX = {
        "&:hover": {
            opacity: 0.8
        },
        color: "#FFFFFF"
    };

    const usernameSX = {
        color: theme.vars.palette.text.primary,
    }

    const typographySX = {
        color: theme.vars.palette.text.tertiary,
    }

    return (
        <S.UserManagementToolContainer>
            <S.UserInfoWrapper>
                <S.AvatarButton
                    onClick={() => handleRedirectToManageAccount()}
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded="true"
                    aria-label="Profile menu"
                >
                    <Avatar>{initials}</Avatar>
                </S.AvatarButton>
                <S.UserNameWrapper>
                    <Typography level="body" sx={usernameSX}>{user?.username}</Typography>
                    <Typography level="bodySmall" sx={typographySX}>manage</Typography>
                </S.UserNameWrapper>
            </S.UserInfoWrapper>
            <S.LogoutButton onClick={() => signOut()}>
                <LogoutIcon sx={logoutButtonSX}/>
            </S.LogoutButton>
        </S.UserManagementToolContainer>
    );
};
