import { TeamType } from "types";
import { apiClientWithAuth } from "./apiClient.http";

type GetMyTeamResponse = Array<{
  id: number;
  name: string;
  modified: string;
  created: string;
}>;

const getMyTeams = async (): Promise<TeamType[]> => {
  const { data } = await apiClientWithAuth.get<GetMyTeamResponse>("/team");

  return data.map((team) => ({
    id: team.id,
    name: team.name,
    createdAt: new Date(team.created),
    modifiedAt: new Date(team.modified),
  }));
};

export const team = { getMyTeams };
