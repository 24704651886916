import React from 'react';
import {Button} from "@mui/joy";
import * as S from "./Pagination.styled";

type PaginationProps = {
    currentPage: number,
    handlePageChange: (page: number) => void
    handleNextPage: () => void,
    handlePreviousPage: () => void,
    totalPages: number,
}

export const Pagination = ({currentPage, handlePreviousPage, handleNextPage, totalPages, handlePageChange} :PaginationProps) => {
    const generatePagination = (pages: number) => {
        if (pages > 4) {
            return new Array(pages).fill(null).map((_, i) => {
                if (
                    i === currentPage -1 ||
                    i === currentPage -2 ||
                    i === currentPage -3 ||
                    i === currentPage +1 ||
                    i === currentPage
                ) {
                    return (
                        <Button
                            key={i + _}
                            variant={currentPage === i + 1 ? "solid" : "outlined"}
                            onClick={() => handlePageChange(i + 1)}
                            color="pagination"
                        >
                            {i + 1}
                        </Button>
                    )
                }
            });
        } else {
            return new Array(pages).fill(null).map((_, i) => (
                <Button
                    key={i + _}
                    variant={currentPage === i + 1 ? "solid" : "outlined"}
                    onClick={() => handlePageChange(i + 1)}
                    color="pagination"
                >
                    {i + 1}
                </Button>
            ));
        }
    }
    return (
        <S.PaginationContainer>
            <Button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(1)}
                color="pagination"
                variant="outlined"
            >
                {"<<"}
            </Button>
            <Button
                disabled={currentPage === 1}
                onClick={handlePreviousPage}
                color="pagination"
                variant="outlined"
            >
                {"<"}
            </Button>
            {generatePagination(totalPages)}
            <Button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                color="pagination"
                variant="outlined"
            >
                {">"}
            </Button>
            <Button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(totalPages)}
                color="pagination"
                variant="outlined"
            >
                {">>"}
            </Button>
        </S.PaginationContainer>
    );
};
