import { extendTheme } from "@mui/joy";

// Nadpisujmy wszystkie style komponentów MUI JOY w tym pliku.
// Jeżeli chcemy dodać do theme nowe wartości np. fonty czy gradienty to trzeba zauktualizować plik theme.d.ts
// Typografia i gradienty wyciągniete z figmy sa poniżej obiektu theme

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        customColors: {
          bg: "#080818",
          elementBg: "#180F2C",
          detail1: "#F9AE43",
          detail1Opacity: "#F9AE434D",
          detail2: "#2FE5A7",
          detail2Opacity: "#2FE5A74D",
          text: "#FFFFFF",
          detail3: "#BE4FFF",
          detail3Opacity: "#BE4FFF4D",
          activeElement: "#FFFFFF33",
          accentLight: "#99B2C6",
          circleLoader: "(#2FE5A7, #2FE5A7, #2FE5A7)",
        },
        boxShadow: {
          primary: "-14px 30px 20px rgba(0, 0, 0, 0.05)",
        },
        background: {
          body: "#180F2C",
          level1: "#080818",
        },
        text: {
          primary: "#FFFFFF",
          tertiary: "#99B2C6",
        },
      },
    },
  },
  components: {
    JoyCard: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.vars.palette.customColors.elementBg,
            color: theme.vars.palette.customColors.text,
            boxShadow: "none",

            // "&:hover": {
            //   color: theme.vars.palette.customColors.detail1,
            // },
          };
        },
      },
    },
    JoyModal: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            "& .MuiModal-backdrop": {
              backdropFilter: "saturate(65%) blur(2px)",
              backgroundColor: "transparent",
            },
          };
        },
      },
    },
    JoyModalDialog: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.vars.palette.customColors.elementBg,
            color: theme.vars.palette.customColors.text,
            boxShadow: theme.vars.palette.boxShadow.primary,
            border: "none",
          };
        },
      },
    },
    JoyModalClose: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.vars.palette.customColors.detail3Opacity,
            color: theme.vars.palette.customColors.detail3,
            "&:hover": {
              backgroundColor: theme.vars.palette.customColors.detail3Opacity,
              color: theme.vars.palette.customColors.detail3,
              opacity: 0.8,
            },
          };
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          if (
            ownerState.color === "danger" &&
            ownerState.variant === "rounded"
          ) {
            return {
              height: "40px",
              width: "40px",
              backgroundColor: theme.vars.palette.customColors.detail3Opacity,
              borderRadius: "50%",
              "& path": {
                fill: theme.vars.palette.customColors.detail3,
              },
              "&:hover": {
                opacity: 0.8,
              },
              circle: {
                "--CircularProgress-progressColor":
                  theme.vars.palette.customColors.detail1Opacity,
              },
            };
          }
          if (ownerState.color === "danger") {
            return {
              backgroundColor: theme.vars.palette.customColors.detail3Opacity,
              "&:hover": {
                backgroundColor: theme.vars.palette.customColors.detail3Opacity,
                opacity: 0.8,
              },
              "&:disabled": {
                backgroundColor: theme.vars.palette.customColors.detail3Opacity,
                opacity: 0.4,
                cursor: "not-allowed",
                pointerEvents: "all",
              },
              "& .MuiButton-loading": {
                backgroundColor: theme.vars.palette.customColors.detail3Opacity,
              },
              "--CircularProgress-progressColor":
                theme.vars.palette.customColors.detail1Opacity,
            };
          }
          if (
            ownerState.color === "pagination" &&
            ownerState.variant === "solid"
          ) {
            return {
              backgroundColor: theme.vars.palette.customColors.detail1,
              textDecoration: "underline",
              color: theme.vars.palette.text.primary,
              border: `1px solid ${theme.vars.palette.customColors.detail1}`,
              "--CircularProgress-progressColor":
                theme.vars.palette.customColors.detail1Opacity,
            };
          }
          if (
            ownerState.color === "pagination" &&
            ownerState.variant === "outlined"
          ) {
            return {
              backgroundColor: "transparent",
              color: theme.vars.palette.text.primary,
              border: `1px solid ${theme.vars.palette.customColors.detail1}`,
              "&:disabled": {
                opacity: "0.2",
                cursor: "not-allowed",
                pointerEvents: "all",
              },
              "--CircularProgress-progressColor":
                theme.vars.palette.customColors.detail1Opacity,
            };
          }
          if (ownerState.color === "primary") {
            return {
              backgroundColor: theme.vars.palette.customColors.detail1,
              "&:hover": {
                backgroundColor: theme.vars.palette.customColors.detail1,
                opacity: 0.8,
              },
              "&&:disabled": {
                backgroundColor: theme.vars.palette.customColors.detail1Opacity,
                cursor: "not-allowed",
                color: theme.vars.palette.customColors.activeElement,
              },
              "--CircularProgress-progressColor":
                theme.vars.palette.customColors.detail1Opacity,
            };
          }
        },
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.vars.palette.background.level1,
            color: theme.vars.palette.customColors.accentLight,
            border: "none",
            outline: "none",

            "&.Joy-focused": {
              "&:before": {
                boxShadow: "none",
              },
            },
            "&&:hover": {
              backgroundColor: theme.vars.palette.background.level1,
              color: theme.vars.palette.customColors.accentLight,
            },
            "&:focus": {
              backgroundColor: theme.vars.palette.background.level1,
              color: theme.vars.palette.customColors.accentLight,
              border: "none",
              outline: "none",
            },
          };
        },
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.vars.palette.background.level1,
            color: theme.vars.palette.customColors.accentLight,
            border: "none",
            outline: "none",

            "&.Joy-focused": {
              "&:before": {
                boxShadow: "none",
              },
            },
            "&&:hover": {
              backgroundColor: theme.vars.palette.background.level1,
              color: theme.vars.palette.customColors.accentLight,
            },
            "&:focus": {
              backgroundColor: theme.vars.palette.background.level1,
              color: theme.vars.palette.customColors.accentLight,
              border: "none",
              outline: "none",
            },
          };
        },
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            color: theme.vars.palette.customColors.accentLight,
            backgroundColor: theme.vars.palette.background.level1,
            outline: "none",
            border: "none",
            "&&:hover": {
              backgroundColor: theme.vars.palette.background.level1,
              color: theme.vars.palette.customColors.accentLight,
            },
            "&&& .Joy-expanded": {
              backgroundColor: theme.vars.palette.background.level1,
            },
            "&&& .MuiSelect-listbox": {
              backgroundColor: theme.vars.palette.background.level1,
            },
          };
        },
      },
    },
    JoyOption: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            color: theme.vars.palette.customColors.accentLight,
            backgroundColor: theme.vars.palette.background.level1,
            outline: "none",
            border: "none",
            "&&:hover": {
              backgroundColor: theme.vars.palette.background.level1,
              color: theme.vars.palette.customColors.accentLight,
            },
            "&&& .Joy-expanded": {
              backgroundColor: theme.vars.palette.background.level1,
            },
          };
        },
      },
    },
    JoyMenuItem: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.vars.palette.background.body,
            color: theme.vars.palette.text.primary,
            "&&:hover": {
              color: theme.vars.palette.text.primary,
              backgroundColor: theme.vars.palette.background.level1,
            },
          };
        },
      },
    },
    JoyMenu: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.vars.palette.background.body,
            border: "none",
            boxShadow: theme.vars.palette.boxShadow.primary,
          };
        },
      },
    },
    JoySheet: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.vars.palette.background.body,
            boxShadow: "none",
            outline: "none",
            border: "none",
          };
        },
      },
    },
    JoyTypography: {
      defaultProps: {
        level: "body",
        levelMapping: {
          headline: "h1",
          headlineSmall: "h2",
          title: "h3",
          titleSmall: "h4",
          body: "p",
          bodySmall: "p",
        },
      },
    },
    JoyAvatar: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            borderRadius: "20px",
          };
        },
      },
    },
  },
  fontFamily: {
    body: "'DM Sans', sans-serif",
  },
  typography: {
    headline: {
      fontSize: "24px",
      lineHeight: "31.25px",
      fontWeight: "700",
      color: "inherit",
    },
    headlineSmall: {
      fontSize: "22px",
      lineHeight: "28,64px",
      fontWeight: "700",
      color: "inherit",
    },
    title: {
      fontSize: "18px",
      lineHeight: "23,44px",
      fontWeight: "500",
      color: "inherit",
    },
    titleSmall: {
      fontSize: "16px",
      lineHeight: "20,83px",
      fontWeight: "500",
      color: "inherit",
    },
    body: {
      fontSize: "14px",
      lineHeight: "18.23px",
      fontWeight: "400",
      color: "inherit",
    },
    bodySmall: {
      fontSize: "12px",
      lineHeight: "15,62px",
      fontWeight: "400",
      color: "inherit",
    },
  },
});

const gradients = {
  chartGradient:
    "linear-gradient(89.96deg, rgba(58, 54, 219, 0.5) -0.98%, rgba(255, 105, 180, 0.35) 100%)",
};

const elements = {
  borderRadius: {
    card: "10px",
    input: "5px",
    button: "7px",
    roundedButton: "22.5px",
    modal: "10px",
  },
  boxShadow: {
    modal: "-14px 30px 20px rgba(0, 0, 0, 0.05);",
  },
};
