import styled from "@emotion/styled";
import { Typography } from "@mui/joy";
import { theme } from "styles";

export const GroupList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const GroupLabel = styled(Typography)<{disabled?: boolean}>`
  color: ${({ theme, disabled }) => disabled ? theme.palette.customColors.activeElement : theme.palette.text.tertiary};
  font-size: 13px;
  font-weight: 600;
`;

export const Group = styled.li``;

export const LinkGroupList = styled.ul`
  margin: ${({ theme }) => theme.spacing(1)} 0;
  list-style: none;
  padding: 0;
  overflow: auto;
`;

export const MenuLinkItem = styled.li<{disabled?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    display: block;
    padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
    text-decoration: none;
    color: ${({ theme, disabled }) => disabled ? theme.palette.customColors.activeElement : theme.palette.text.primary};
    background-color: transparent;
    transition: 0.15s;
    border-radius: ${({ theme }) => theme.radius.sm};
    cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
  }

  &:hover {
    &:before {
      content: "";
      position: absolute;
      left: -20px;
      height: 35px;
      width: 78px;
      background-color: ${({ theme, disabled }) => disabled ? "none" : theme.palette.customColors.activeElement};
      border-radius: 0 5px 5px 0;
      z-index: 1;
    }
    
    a {
      color: ${({theme, disabled}) => disabled ? theme.palette.customColors.activeElement : theme.palette.customColors.detail1};
      opacity: 1;
    }
  }
`;

export const LinkContent = styled.div`
    display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
`;

export const ExpandedList = styled.ul`
  position: relative;
    display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
  max-height: 155px;
  overflow: auto;
  scroll-snap-type: y mandatory;
  
  
  &::-webkit-scrollbar {
    background: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.vars.palette.customColors.detail1};
    border-radius: 10px;
  }
`;

export const ExpandedLinkItem = styled.li`
  list-style: none;
  width: 130px;
  padding-right: 8px;
  scroll-snap-align: start;

  a {
    width: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: ${({theme}) => theme.palette.text.primary};
    text-decoration: none;
    
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  
  &:hover {
    a, p {
      color: ${({theme}) => theme.palette.customColors.detail1};
      opacity: 1;
    }
  }
`;

export const ExpandIconWrapper = styled.div`
  color: ${({theme}) => theme.palette.text.primary};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
