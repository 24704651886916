import React from "react";
import * as S from "./SidebarLogo.styled";
import { assets } from "../../../../assets";
import { routes } from "../../../../utils";
import { Link } from "react-router-dom";

const { LogoBlackSvg } = assets.brand;

export const SidebarLogo = () => {
  return (
    <S.LogoWrapper>
      <Link to={routes.dashboard.index}>
        <LogoBlackSvg style={{ fill: "white" }} height={40} width={150} />
      </Link>
    </S.LogoWrapper>
  );
};
