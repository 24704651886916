import React from "react";
import * as S from "./ContractCard.styled";
import { ContractType } from "../../../pages/dashboard/project-details/components/ListOfContracts/ListOfContracts";
import { Badge, Button, Typography } from "@mui/joy";
import { theme } from "../../../styles";
import {Link, generatePath, useParams, useNavigate} from "react-router-dom";
import { routes } from "../../../utils";

type ContractCardProps = {
  contract: ContractType;
  disabled: boolean;
};

export const ContractCard = ({ contract, disabled }: ContractCardProps) => {
  const { id, name, pragma, _count } = contract;
  const nameSX = {
    fontWeight: "bold",
    color: theme.vars.palette.text.primary,
  };

  const navigate = useNavigate();

  const pragmaSX = {
    background: theme.vars.palette.customColors.detail2Opacity,
    color: theme.vars.palette.customColors.detail2,
    padding: "4px 6px",
    borderRadius: "sm",
  };

  const handleRedirect = () => {
    navigate(generatePath(routes.dashboard.contractVisualizationAbsolute, {projectId, contractId: id}));
  }

  const { id: projectId } = useParams();

  return (
    <S.ContractCardContainer>
      <Typography level="titleSmall" sx={nameSX}>
        {name}.sol
      </Typography>
      <S.ButtonAndPragmaWrapper>
        <Typography level="body" sx={pragmaSX}>
          {pragma}
        </Typography>
        {_count.Recommendation > 0 && (
          <Badge badgeContent={_count.Recommendation}>
            <Button
              component={Link}
              to={generatePath(routes.dashboard.aiRecommendationsAbsolute, {
                id: projectId,
                contractId: id,
              })}
              disabled={disabled}
            >
              AI Recommendations
            </Button>
          </Badge>
        )}
        <Button onClick={handleRedirect} disabled={disabled}>Visualization</Button>
      </S.ButtonAndPragmaWrapper>
    </S.ContractCardContainer>
  );
};
