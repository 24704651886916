import React from 'react';
import {Button, Card} from "@mui/joy";
import * as S from "./ProjectCard.styled";
import {MyProjectData} from "../../../pages/dashboard/my-projects/MyProjects";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {cutText, routes} from "../../../utils";
import {generatePath, useNavigate} from "react-router-dom";

type ProjectCardProps = {
    onDelete?: () => void,
    project: MyProjectData,
}

export const ProjectCard = ({onDelete, project}: ProjectCardProps) => {
    const navigate = useNavigate();
    const {id, name, description} = project;

    const handleRedirectToDetails = () => {
        navigate(generatePath(routes.dashboard.projectDetailsAbsolute, {id: project.id}))
    };

    const onDeleteClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onDelete && onDelete();
    };

    const cardSX = {
        width: 250,
        height: 200,
    };

    return (
        <S.CardContainer
            key={id}
            onClick={() => handleRedirectToDetails()}
        >
            <Card sx={cardSX}>
                <S.CustomCardContent>
                    <S.InfoWrapper>
                        <S.Info>
                            <strong>{name}</strong>
                        </S.Info>
                        <S.Info>
                            {cutText(description, 100, "...")}
                        </S.Info>
                    </S.InfoWrapper>
                    <S.ButtonsWrapper>
                        {onDelete && (
                            <Button color="danger" variant="rounded" onClick={(e) => onDeleteClick(e)}>
                                <DeleteForeverIcon/>
                            </Button>
                        )}
                    </S.ButtonsWrapper>
                </S.CustomCardContent>
            </Card>
        </S.CardContainer>
    )
};