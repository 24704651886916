import styled from "@emotion/styled";

export const PaginationContainer = styled.nav`
    display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 2px;
    
    button {
      width: 10px;
      height: 10px;
    }
  }
`;