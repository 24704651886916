import axios from "axios";

import config from "config";
import { LOCAL_STORAGE_KEYS } from "../../constants";

const baseAxiosConfig = {
  baseURL: config.API_URL,
  headers: {
    "content-type": "application/json",
  },
};

export const apiClient = axios.create(baseAxiosConfig);

export const apiClientWithAuth = axios.create(baseAxiosConfig);

apiClientWithAuth.interceptors.request.use(
  (conf) => {
      // @ts-ignore
    if (!conf.headers) conf.headers = {};
    conf.headers.Authorization = `Bearer ${localStorage.getItem(
      LOCAL_STORAGE_KEYS.AUTH_TOKEN
    )}`;
    return conf;
  },
  (error) => Promise.reject(error)
);
