import {useMutation} from "react-query";
import {http} from "../helpers";
import {UpdateProjectRequest} from "../helpers/http/project.http";

type MutationFnArg = {
    id: number;
    data: UpdateProjectRequest;
}
export const useEditProjectById = () => {
    return useMutation(
        (data :MutationFnArg) => http.project.editById(data.id, data.data),
    );
};