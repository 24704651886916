import { apiClientWithAuth } from "./apiClient.http";

type CreateProjectRequest = {
  teamId: number;
  name: string;
  description: string;
};

export type UpdateProjectRequest = {
  name: string;
  description?: string;
}

const create = async (createProjectData: CreateProjectRequest) => {
  const { data } = await apiClientWithAuth.post("/project", createProjectData);

  return data;
};

const getMyProjects = async () => {
  const { data } = await apiClientWithAuth.get("/project");

  return data;
}

const deleteById = async (id: number) => {
  const { data } = await apiClientWithAuth.delete(`/project/${id}`);

  return data;
}

const editById = async (id: number, updateProjectData: UpdateProjectRequest) => {
  const { data } = await apiClientWithAuth.patch(`/project/${id}`, updateProjectData);

  return data;
}

const getById = async (id: number) => {
  const { data } = await apiClientWithAuth.get(`/project/${id}`);

  return data;
}

export const project = {
  create,
  getMyProjects,
  deleteById,
  editById,
  getById,
};
