import { CircularProgress } from "@mui/joy";

import { LoadingScreenWrapper } from "./LoadingScreen.styled";
import {theme} from "../../styles";

export const LoadingScreen = () => {
    const circularProgressSX = {
        "--CircularProgress-progressColor": theme.vars.palette.customColors.detail3,
        "--CircularProgress-trackColor": theme.vars.palette.customColors.detail3Opacity,
    }
  return (
    <LoadingScreenWrapper>
      <CircularProgress sx={circularProgressSX} />
    </LoadingScreenWrapper>
  );
};
