import styled from "@emotion/styled";

export const LoadingScreenWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.palette.background.level1};
`;
