import { PropsWithChildren, useRef } from "react";

import { LayoutContext } from "contexts";
import { useBoolean, useSwipe } from "hooks";

import { PageWrapper as StyledPageWrapper } from "./PageWrapper.styled";

export const PageWrapper = ({ children }: PropsWithChildren) => {
  const [isSidebarOpened, openSidebar, closeSidebar, toggleSidebar] =
    useBoolean(true);

  const pageRef = useRef<HTMLDivElement>(null);

  // useSwipe(pageRef, 150, (swipeDirection) => {
  //   if (swipeDirection === "left") {
  //     closeSidebar();
  //   } else if (swipeDirection === "right") {
  //     openSidebar();
  //   }
  // });

  const layoutContextValue = {
    isSidebarOpened,
    openSidebar,
    closeSidebar,
    toggleSidebar,
  };

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      <StyledPageWrapper ref={pageRef} isSidebarOpened={isSidebarOpened}>
        {children}
      </StyledPageWrapper>
    </LayoutContext.Provider>
  );
};
