import styled from "@emotion/styled";

type SidePanelProps = {
  isOpen: boolean;
};

export const SidePanel = styled.aside<SidePanelProps>`
  position: relative;
  grid-area: side-panel;
  width: 224px;
  background-color: ${({ theme }) => theme.palette.background.body};
  height: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

  z-index: 100;

  @media (max-width: 768px) {
    position: absolute;
    top: 0px;
    left: ${({isOpen}) => isOpen ? "0" : "-224px"};
    bottom: 0px;
    width: 224px;
  }
`;

export const SidePanelContent = styled.div<SidePanelProps>`
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

type SidePanelButtonProps = {
  isOpen: boolean;
};

export const SidePanelButton = styled.button<SidePanelButtonProps>`
  display: none;

  @media (max-width: 768px) {
    position: absolute;
    top: 40px;
    right: -24px;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 25px;
    height: 36px;
    padding: 0;
    border: none;
    background-color: ${({ theme }) => theme.palette.background.body};
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.text.primary};
    display: flex;
    justify-content: flex-end;
    padding-right: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 1px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 0px 3px 0px;
    
    &:hover {
      background-color: ${({ theme }) => theme.palette.background.body};
    }
  }

  &,
  path {
    transition: 0.15s;
  }

  svg {
    transform: rotate(${({ isOpen }) => (isOpen ? "0deg" : "180deg")});
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.customColors.accentLight};
    path {
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;
