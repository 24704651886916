import { useContext } from "react";

import { LayoutContext } from "contexts";

export const useLayoutContext = () => {
  const layoutContext = useContext(LayoutContext);

  if (!layoutContext) {
    throw new Error("useLayoutContext hook is used outside of provider");
  }

  return layoutContext;
};
