import styled from "@emotion/styled";
import {CardContent} from "@mui/joy";

export const CardContainer = styled.li`
    list-style: none;
  cursor: pointer;
  width: 300px;
`;

export const CustomCardContent = styled(CardContent)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  flex-direction: column;
  
  p {
    margin: 0;
    text-align: center;
  }
`;

export const Info = styled.p`
  display: flex;
  justify-content: flex-start;
  gap: 5px;
`;