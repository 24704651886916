import styled from "@emotion/styled";

type PageWrapperProps = {
  isSidebarOpened: boolean;
};

export const PageWrapper = styled.div<PageWrapperProps>`
  position: relative;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;

  display: grid;
  grid-template-areas: " header header" "side-panel content";
  grid-template-rows: auto 1fr;
  grid-template-columns: ${({ isSidebarOpened }) =>
    isSidebarOpened ? "auto 1fr" : "0"};

  background-color: ${({ theme }) => theme.palette.background.level1};
`;
