import { isAxiosError } from "axios";

type GetApiErrorMessagesReturnType = {
  firstMessage: string;
  messages: string[];
};

export const getApiErrorMessages = (
  error: any,
  defaultMessage = "Something went wrong, please try again later!"
): GetApiErrorMessagesReturnType => {
  if (isAxiosError(error)) {
    const message = error.response?.data.message;

    if (message && Array.isArray(message) && message.length > 0) {
      const messages = message;
      return {
        messages,
        firstMessage: messages[0],
      };
    }

    if (typeof message === "string") {
      return {
        messages: [message],
        firstMessage: message,
      };
    }
  }

  if (typeof error?.message === "string") {
    return {
      messages: [error.message],
      firstMessage: error.message,
    };
  }

  return {
    messages: [defaultMessage],
    firstMessage: defaultMessage,
  };
};
