import {lazy, Suspense} from "react";
import {CssVarsProvider} from "@mui/joy";
import {Route, Navigate} from "react-router-dom";
import {Routes} from "react-router";
import {QueryClient, QueryClientProvider} from "react-query";
import "@fontsource/public-sans";

import {routes} from "./utils";
import {DashboardLayout} from "./layout";
import {LoadingScreen} from "components";
import {theme} from "./styles";

const queryClient = new QueryClient();



const AuthProvider = lazy(() => import("./providers/auth.provider"));
const RequireAuth = lazy(() => import("./components/RequireAuth"));
const SignInPage = lazy(() => import("./pages/SignIn.page"));
const SignUpPage = lazy(() => import("./pages/SignUp.page"));
const ActivateAccountPage = lazy(() => import("./pages/ActivateAccount.page"));
const ActivatePage = lazy(() => import("./pages/Activate.page"));
const RememberPasswordPage = lazy(
    () => import("./pages/RememberPassword.page")
);


const ResetPasswordPage = lazy(() => import("./pages/ResetPassword.page"));
const DashboardRouting = lazy(() => import("./pages/dashboard"));

function App() {
    return (
            <QueryClientProvider client={queryClient}>
                <CssVarsProvider theme={theme}>
                    <main>
                        <AuthProvider>
                            <Suspense fallback={<LoadingScreen/>}>
                                <Routes>
                                    <Route
                                        path={routes.home}
                                        element={<Navigate replace to={routes.signIn}/>}
                                    />
                                    <Route path={routes.signIn} element={<SignInPage/>}/>
                                    <Route path={routes.signUp} element={<SignUpPage/>}/>
                                    <Route
                                        path={routes.activateAccount}
                                        element={<ActivateAccountPage/>}
                                    />
                                    <Route path={routes.activate} element={<ActivatePage/>}/>
                                    <Route
                                        path={routes.rememberPassword}
                                        element={<RememberPasswordPage/>}
                                    />
                                    <Route
                                        path={routes.resetPassword}
                                        element={<ResetPasswordPage/>}
                                    />
                                    <Route
                                        path={routes.dashboard.index + "/*"}
                                        element={
                                            <RequireAuth>
                                                <DashboardLayout>
                                                    <DashboardRouting/>
                                                </DashboardLayout>
                                            </RequireAuth>
                                        }
                                    />
                                </Routes>
                            </Suspense>
                        </AuthProvider>
                    </main>
                </CssVarsProvider>
            </QueryClientProvider>
    );
}

export default App;
