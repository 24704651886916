import {PropsWithChildren} from "react";

import {BaseLayout} from "../BaseLayout";
import {DashboardNavigation, UserManagementTool} from "./components";
import {SidebarLogo} from "./components/SidebarLogo";

type DashboardLayoutProps = PropsWithChildren;

const {PageWrapper, SidePanel, PageContent} = BaseLayout;

export const DashboardLayout = ({children}: DashboardLayoutProps) => {
    return (
        <PageWrapper>
            <SidePanel>
                <div style={{display: "flex", flexDirection: "column", gap: "10px", maxHeight: "calc(100vh - 130px)", overflow: "auto"}}>
                    <SidebarLogo/>
                    <DashboardNavigation/>
                </div>
                <UserManagementTool/>
            </SidePanel>
            <PageContent>
                <div>{children}</div>
            </PageContent>
        </PageWrapper>
    );
};
