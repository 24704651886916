import {Divider, Typography} from "@mui/joy";
import {generatePath, Link} from "react-router-dom";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddBoxIcon from '@mui/icons-material/AddBox';
import GavelIcon from '@mui/icons-material/Gavel';
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HomeIcon from '@mui/icons-material/Home';

import {routes} from "utils";
import {
    GroupList,
    Group,
    GroupLabel,
    LinkGroupList,
    MenuLinkItem,
    LinkContent,
    ExpandedLinkItem,
    ExpandedList,
    ExpandIconWrapper,
} from "./DashboardNavigation.styled";
import {useFetchMyProjects, useLayoutContext, usePageWide} from "../../../../hooks";
import {useEffect, useState} from "react";
import {MyProjectData} from "../../../../pages/dashboard/my-projects/MyProjects";

type CollapseData = {
    icon: JSX.Element;
    label: string;
    to: string;
    id: string;
};

type LinkData = {
    label: string;
    to: string;
    disabled?: boolean;
    icon: JSX.Element;
    expand?: CollapseData[];
};

type LinkGroup = {
    label: string;
    links: LinkData[];
    disabled?: boolean;
};

export const DashboardNavigation = () => {
    const [isMyProjectsExpanded, setIsMyProjectsExpanded] = useState(false);
    const {data: projects, status} = useFetchMyProjects();
    const {closeSidebar, openSidebar} = useLayoutContext();
    const {pageWide} = usePageWide();

    const toggleExpandMyProjects = () => {
        setIsMyProjectsExpanded(!isMyProjectsExpanded);
    };

    const handleCloseSidebar = () => {
        if (pageWide < 768) {
            closeSidebar();
        }
    };

    useEffect(() => {
        if (pageWide > 768) {
            openSidebar();
        }
    }, [pageWide > 768])


    // TODO: Assign correct links when pages will be created
    const linkGroups: LinkGroup[] = [
        {
            label: "Organization",
            disabled: true,
            links: [
                {
                    label: "My organization",
                    to: routes.dashboard.index,
                    disabled: true,
                    icon: <CorporateFareIcon/>,
                },
                {
                    label: "Manage",
                    to: routes.dashboard.index,
                    disabled: true,
                    icon: <DomainDisabledIcon/>,
                },
            ],
        },
        {
            label: "My Profile",
            disabled: true,
            links: [
                {
                    label: "Management",
                    to: routes.dashboard.index,
                    disabled: true,
                    icon: <ManageAccountsIcon/>,
                },
                {
                    label: "Settings",
                    to: routes.dashboard.index,
                    disabled: true,
                    icon: <SettingsIcon/>,
                }
            ]
        },
        {
            label: "Projects",
            links: [
                {
                    label: "Dashboard",
                    to: routes.dashboard.index,
                    icon: <HomeIcon />,
                },
                {
                    label: "Create project",
                    to: routes.dashboard.createProjectAbsolute,
                    icon: <AddBoxIcon/>
                },
                {
                    label: "My projects",
                    to: routes.dashboard.myProjectsAbsolute,
                    icon: <AccountTreeIcon/>,
                    expand: projects && projects.map((project: MyProjectData) => {
                        return {
                            icon: <DescriptionIcon/>,
                            label: project.name,
                            to: generatePath(routes.dashboard.projectDetailsAbsolute, {id: project.id}),
                            id: project.id,
                        }
                    })
                },
                {
                    label: "My contracts",
                    to: routes.dashboard.index,
                    icon: <GavelIcon/>,
                },
            ],
        },
    ];

    return (
        <GroupList>
            {linkGroups.map((group) => (
                <Group key={group.label}>
                    <GroupLabel disabled={group.disabled}>{group.label}</GroupLabel>
                    <Divider/>
                    <LinkGroupList>
                        {group.links.map((link) => (
                            <>
                                <MenuLinkItem disabled={link.disabled}
                                              onClick={() => handleCloseSidebar()}
                                              key={link.label}
                                >
                                    <Link to={link.disabled ? "#" : link.to}>
                                        <LinkContent>
                                            {link.icon}
                                            {link.label}
                                        </LinkContent>
                                    </Link>
                                    <ExpandIconWrapper onClick={toggleExpandMyProjects}>
                                        {link.expand && (isMyProjectsExpanded ? <RemoveIcon /> : <AddIcon /> )}
                                    </ExpandIconWrapper>
                                </MenuLinkItem>
                                {
                                    link.expand &&
                                    status === "success" &&
                                    isMyProjectsExpanded && (
                                    <ExpandedList>
                                        {link.expand.map((project) => (
                                            <ExpandedLinkItem key={project.id}>
                                                <Link to={project.to}>
                                                    {project.icon}
                                                    <Typography level="bodySmall">
                                                        {project.label}
                                                    </Typography>
                                                </Link>
                                            </ExpandedLinkItem>
                                        ))}
                                    </ExpandedList>
                                )}
                            </>
                        ))}
                    </LinkGroupList>
                </Group>
            ))}
        </GroupList>
    );
};
