import { createContext } from "react";

export interface LayoutContextType {
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
  isSidebarOpened: boolean;
}

export const LayoutContext = createContext<LayoutContextType | null>(null);
