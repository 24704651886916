import { apiClientWithAuth } from "./apiClient.http";

const getContractsByProjectId = async (projectId: number) => {
  const { data } = await apiClientWithAuth.get(
    `/project/${projectId}/contract`
  );

  return data;
};

const uploadContract = async (projectId: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  console.log(formData.get("file"));
  const { data } = await apiClientWithAuth.post(
    `/project/${projectId}/contract/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
};

const getContractById = async (projectId: number, contractId: number) => {
  const { data } = await apiClientWithAuth.get(
    `/project/${projectId}/contract/${contractId}`
  );

  return data;
};

export const contracts = {
  getContractsByProjectId,
  uploadContract,
  getContractById,
};
