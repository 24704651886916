import {PropsWithChildren} from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useLayoutContext} from "hooks";

import {
    SidePanel as StyledSidePanel,
    SidePanelContent,
    SidePanelButton,
} from "./SidePanel.styled";

export const SidePanel = ({children}: PropsWithChildren) => {
    const {isSidebarOpened, toggleSidebar} = useLayoutContext();
    return (
        <StyledSidePanel isOpen={isSidebarOpened}>
            <SidePanelContent isOpen={isSidebarOpened}>{children}</SidePanelContent>
            <SidePanelButton isOpen={isSidebarOpened} onClick={() => toggleSidebar()}>
                <ChevronLeftIcon />
            </SidePanelButton>
        </StyledSidePanel>
    );
};
