import React from 'react';
import * as S from "./RecentProjectsCard.styled";
import {Card, Typography} from "@mui/joy";
import {generatePath, useNavigate} from "react-router-dom";
import {humanizeDuration, routes} from "../../../utils";
import {MyProjectData} from "../../../pages/dashboard/my-projects/MyProjects";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import {theme} from "../../../styles";
import DescriptionIcon from '@mui/icons-material/Description';

type RecentProjectCardProps = {
    project: MyProjectData;
    lastSeen: Date;
}

export const RecentProjectsCard = ({project, lastSeen}: RecentProjectCardProps) => {
    const {name, id} = project;
    const navigate = useNavigate();

    const handleRedirectToDetails = () => {
        navigate(generatePath(routes.dashboard.projectDetailsAbsolute, {id: project.id}))
    };

    const dashboardVariantCardSX = {
        height: "auto",
        padding: "10px 20px",
    };

    const lastSeenSX = {
        color: theme.vars.palette.text.primary,
    }

    return (
        <S.CardContainer
            key={id}
            onClick={() => handleRedirectToDetails()}
        >
            <Card sx={dashboardVariantCardSX}>
                <S.CustomCardContent>
                    <S.TitleWrapper>
                        <DescriptionIcon />
                        <Typography level="body">
                            <strong>{name}</strong>
                        </Typography>
                    </S.TitleWrapper>
                    <S.LastSeen>
                        <AccessTimeFilledIcon />
                        <Typography level="bodySmall" sx={lastSeenSX}>
                            {humanizeDuration(lastSeen)}
                        </Typography>
                    </S.LastSeen>
                </S.CustomCardContent>
            </Card>
        </S.CardContainer>
    );
};