import {useState} from 'react';

export const usePagination = (data :[], itemsPerPage: number) => {
    const [currentPage, setCurrentPage] = useState(1);

    const currentPageNumber = (currentPage * itemsPerPage) - itemsPerPage;
    const paginatedProjects = data?.slice(currentPageNumber, currentPageNumber + itemsPerPage);
    const totalPages = Math.ceil(data?.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage >= (data?.length / itemsPerPage)) return;
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage === 1) return;
        setCurrentPage(currentPage - 1);
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    }

    return {
        paginatedProjects,
        currentPage,
        handleNextPage,
        handlePreviousPage,
        handlePageChange,
        totalPages,
    }
};