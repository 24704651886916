import React from "react";
import { USER_AUTH_STATE } from "../constants";
import { UserType } from "types";

export interface AuthContextType {
  user: UserType | null;
  signIn: (email: string, password: string) => Promise<boolean>;
  signOut: () => Promise<boolean>;
  signUp: (email: string, password: string) => Promise<boolean>;
  confirm: (token: string) => Promise<boolean>;
  resendActivation: (token: string) => Promise<boolean>;
  rememberPassword: (email: string) => Promise<boolean>;
  resetPassword: (token: string, password: string) => Promise<boolean>;
  refreshUserData: () => Promise<void>;
  userAuthState: USER_AUTH_STATE;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export default AuthContext;
